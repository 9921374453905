html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent;
  line-height: 1.15;
}
body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
  padding-right: 0px !important;
  background: #fff !important;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* overflow-x: hidden; */
}
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  scrollbar-width: 8px !important;
  margin: 5px;
  padding: 10px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px #0000004d;
  border-radius: 10px;
  scrollbar-width: 8px !important;
}
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  /* box-shadow: inset 0 0 6px #ed6c02; */
  background-color: #19588fab;
  scrollbar-width: 8px !important;
}
body {
  margin: 0;
  padding: 0;
  background-size: cover;
  position: relative;
}

#root {
  min-height: 100%;
  position: relative;
}

#main {
  padding-bottom: 40px;
}

#footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 40px; /* Height of the footer */
  transition: all 0.25s ease;
}

#inner-main.drawer-active footer {
  width: calc(100% - 15rem);
}

/* App Bar Css */
.app-bar {
  background-color: #fff !important;
  color: #000 !important;
}

.app-bar .brand_logo {
  height: 60px;
  /* width: 48px; */
  /* border-radius: 50%; */
}

.app-bar .left,
.app-bar .institution-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.app-bar .left {
  width: 250px;
}

.app-bar .institution-name {
  max-width: 25vw;
}

/* Drawer Css */
#drawer {
  height: 100vh;
  width: 15rem;
  top: 0;
  left: -15rem;
  transition: left 0.25s ease;
  background-color: #fff;
  border-right: 1px solid #dee2e6;
  /* box-shadow: 1px 0 15px -5px #888; */
}

.drawer-sm #drawer {
  z-index: 1032;
}

#inner-main {
  width: 100%;
  transition: padding 0.25s ease;
  padding-left: 0px;
}

#drawer.active {
  left: 0;
}

#inner-main.drawer-active {
  padding-left: 15rem;
}

#drawer-void {
  top: 0;
  width: 100%;
  height: 100vh;
  z-index: 1031;
  background-color: rgba(0, 0, 0, 0.5);
  transition: all 0.25s ease;
}

#drawer .drawer-logo {
  padding: 1.5rem 1rem 1rem;
  text-align: center;
  background-color: #f3f4f6;
  border-bottom: 1px solid #078ece !important;
}

#drawer .drawer-logo img {
  height: 83px;
  /* width: 64px; */
  /* border: 1px solid rgb(228, 227, 227); */
}

/* #drawer .menu-header {
  font-weight: bold;
  color: #015198;
} */

/* #drawer .nav-item a {
  text-decoration: none;
} */

#drawer .nav-item.active {
  /* background-color: #fde9d3; */
  /* background-color: #fef4e8; */
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

#drawer .nav-item .item-icon {
  min-width: 32px !important;
}

.fab-container2 {
  position: fixed;
  bottom: 68px;
  right: 0;
}

.fab-container {
  position: fixed;
  bottom: 0;
  right: 0;
}

.truncatable-text,
.breadcrumb-item.truncatable,
.text-truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

td,
th {
  vertical-align: middle !important;
}

.truncatable-text {
  width: 250px;
}

.breadcrumb-item.truncatable {
  max-width: 28vw;
}

.text-truncate {
  max-width: 230px;
}

button:focus {
  outline: none !important;
  box-shadow: none !important;
}

.btn:focus {
  outline: none !important;
  box-shadow: none !important;
}

.elevated-0 {
  box-shadow: 0px 0px 18px -5px rgb(0 0 0 / 14%);
}

.elevated-1 {
  box-shadow: 0 4px 8px 0 rgb(17 22 26 / 16%), 0 4px 8px 0 rgb(17 22 26 / 8%),
    0 8px 16px 0 rgb(17 22 26 / 4%);
}

.elevated-2 {
  box-shadow: 0px 6px 6px -3px rgb(0 0 0 / 20%),
    0px 10px 14px 1px rgb(0 0 0 / 14%), 0px 4px 18px 3px rgb(0 0 0 / 12%);
}

.elevated-bottom-2 {
  box-shadow: 0px 16px 16px -5px rgb(0 0 0 / 30%);
}

a.hover-text-white:hover {
  color: white !important;
}

a button {
  text-decoration: none;
}

.expected-result-card:hover,
.indicator-card:hover {
  box-shadow: 0 4px 8px 0 rgb(17 22 26 / 16%), 0 4px 8px 0 rgb(17 22 26 / 8%),
    0 8px 16px 0 rgb(17 22 26 / 4%);
}

.expected-result-card.not-assigned {
  border: 5px solid red !important;
}

/* .btn-link {
  text-decoration: none !important;
} */

.btn-link:hover {
  color: blue !important;
}

.rounded-left-bottom {
  border-radius: 32px !important;
  border-top-left-radius: 0px !important;
}

.rounded-right-bottom {
  border-radius: 32px !important;
  border-bottom-right-radius: 0px !important;
}

.bread-crumb a.active,
nav a.active {
  color: yellow !important;
  text-decoration: underline !important;
}

.summary-card {
  color: #000;
  background-color: #fffaeb;
  text-transform: uppercase;
}

.summary-card .badge-dark {
  background-color: #6e7985 !important;
}

.menu-item {
  display: block;
  max-width: 24vw !important;
}

.role {
  display: block !important;
  max-width: 15vw !important;
}

.summary-card .d-block {
  font-size: 12px;
}

.loader {
  height: 100vh;
  position: relative;
}

.loader img {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.roles span {
  font-size: 14px;
  display: flex;
  align-items: center;
  /* font-weight: bold; */
}

/* =================================== */

.tree-node {
  display: flex;
  align-items: center;
  min-height: 40px;
  max-height: 40px;
}

.tree-node .tree-icon {
  margin-right: 8px;
  border-radius: 50%;
  cursor: pointer;
  background-repeat: no-repeat;
}

.tree-node .tree-icon .open-icon {
  /* background-image: url('../../icons/plus.svg'); */
  width: 12px;
  height: 12px;
  background-size: contain;
}

.tree-node .tree-icon .close-icon {
  /* background-image: url('../../icons/minus.svg'); */
  width: 12px;
  height: 12px;
  background-size: contain;
}

.tree-node .tree-icon .empty-icon {
  /* background-image: url('../../icons/empty.svg'); */
  width: 12px;
  height: 12px;
  background-size: contain;
}

.node-content-wrapper {
  cursor: pointer;
  min-height: 40px;
  max-height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  width: 100%;
  padding: 6px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.2s ease;
}

.node-content-wrapper:hover {
  background-color: rgba(255, 0, 0, 0.1);
}

.node-content-wrapper.node-selected {
  border-left: 4px solid red;
}

.node-content-wrapper.titles {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.node-content-wrapper.node-title {
  font-weight: 600;
}

.node-content-wrapper.node-subtitle {
  margin-top: 4px;
  font-size: 10px;
}

.children-length {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 24px;
  height: 16px;
  background-color: red;
  color: white;
  padding: 4px;
  font-size: 12px;
  border-radius: 16px;
  margin-left: 12px;
  font-weight: 600;
}

.tree-wrapper {
  width: 50%;
  padding: 0 0 0 16px;
}

.tree-wrapper.simple {
  overflow: auto;
}

.modules-menu {
  background-color: #fff;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.12); */
}

.modules-menu button,
.modules-menu a {
  /* border: 1px solid #088dce; */
  color: #2a7790;
  font-size: 0.75rem;
  border-radius: 0px !important;
  margin-right: 10px !important;
  font-weight: 500;
  text-align: center;
  transition: all 0.2s ease-in-out;
  text-decoration: none;

  /* border: 1px solid #088dce; */
}

.modules-menu button.active,
.modules-menu a.active {
  color: #fff;
  background-color: #088dce;
}

.modules-menu button.active:hover {
  color: #fffb00 !important;
}

.modules-menu button.active img.invert {
  filter: invert(298%) sepia(152%) saturate(659%) hue-rotate(300deg)
    brightness(94%) contrast(245%);
}

.MuiTreeItem-content {
  /* padding-top: 1px !important;
  padding-bottom: 1px !important; */
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.MuiTreeItem-content .add-padding {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.pagination {
  list-style: none;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
}

/* .pagination .btn:hover {
  background-color: #bcc7d3 !important;
  color: #000 !important;
} */

/* .pagination .active {
  background-color: #d9b815 !important;
}

.pagination .active:hover {
  background-color: #d9b815 !important;
  color: #fff !important;
} */

.elevated {
  box-shadow: 0 4px 8px 0 rgb(17 22 26 / 16%), 0 4px 8px 0 rgb(17 22 26 / 8%),
    0 8px 16px 0 rgb(17 22 26 / 4%);
}

.form-control {
  border-width: 1px !important;
  border-color: #17a2b8;
}
.form-control:focus {
  border-color: #2566e8 !important;
  box-shadow: none !important;
  border-width: 2px !important;

  background-color: #fff !important;
  color: rgba(0, 0, 0, 0.87) !important;
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0mv !important;

  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
  border-radius: 4px !important;
  /* box-shadow: 0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%)!important;; */
}

.searchIcon {
  position: absolute;
  top: 8px;
  right: 24px;
  color: #17a2b8;
}
.loaderIcon {
  position: absolute;
  top: 8px;
  right: 24px;
  direction: rtl;
  color: #17a2b8;
  width: 24px !important;
}
.w-100 {
  width: 100%;
}
.h-50 {
  height: 60px;
}

.exam__dashboard {
  width: 100%;
}
.exam__dashboard .top_cont {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding: 0px 20px; */
  flex-wrap: wrap;
}
.exam__dashboard .top_cont .card_item {
  background-color: #fff;
  display: flex;
  justify-self: start;
  align-items: center;
  border-radius: 10px;
  padding: 20px;
  margin: 10px;
  max-width: 280px;
  flex: 1;
  border: 1px solid var(--maincl-lght-l);

  box-shadow: 0px 5px 5px 0px rgba(#2a7790, 0.05);
  -moz-box-shadow: 0px 5px 5px 0px rgba(#2a7790, 0.05);
  -webkit-box-shadow: 0px 5px 5px 0px rgba(#2a7790, 0.05);
}
.exam__dashboard .top_cont .card_item .img_icon {
  width: 80px;
  height: 80px;
  position: relative;
  overflow: hidden;
}
.exam__dashboard .top_cont .card_item .img_icon img {
  position: absolute;
  width: 75%;
  top: 50%;
  left: 0px;
  transform: translateY(-50%);
}

.exam__dashboard .top_cont .details_con h2 {
  font-size: 1rem;
  font-weight: 400;
  color: var(--maincl-lght);
}
.exam__dashboard .top_cont .details_con h3 {
  font-size: 2rem;
  font-weight: 600;
  color: var(--fnheadingcl);
  font-family: "Overpass", sans-serif;
}
thead th {
  border-bottom-width: 1px !important;
}

.tablex thead th {
  vertical-align: bottom;
  border-bottom: thin solid #dee2e6 !important;
  /* text-align: right; */
}
.tablex td {
  width: 130px;
  padding: 0.3rem;
  /* text-align: right; */
}

.align-level-centered {
  text-align: center !important;
}

.align-indexvalue-right {
  text-align: right !important;
}

.tablex td input {
  position: relative;
  padding: 0.3rem;
  /* vertical-align: ; */
  border: 1px solid rgb(7, 142, 206);
  width: 100%;
  text-align: right !important;
}

.tablex td input[type="checkbox"] {
  transform: scale(1.5);
  padding: 0.3rem;
  margin-top: 8px;
  z-index: 0 !important;
}

/* td.span {
  table-layout: fixed;
  width: 500px!important;
} */

.hovered-td {
  background-color: #e9ecef !important;
}
.hovered-td-box {
  padding: 0 !important;
  background-color: #e9ecef !important;
}
.hovered-row {
  background-color: #e9ecef !important;
}

.span {
  display: inline-block;
  /* width: 300px !important; */
}

.structure-unit-drawer {
  width: 450px;
  /* background-color: hsl(0deg 0% 0% / 4%);
  height: 50vh; */
}

.fixTableHead {
  overflow-y: auto;
}
.fixTableHead thead th {
  position: sticky !important;
  top: 0;
  z-index: 1 !important;
}

table {
  border-collapse: collapse;
  width: 100%;
}
th,
td {
  padding: 8px 15px;
}
th {
  background: #f3f4f6;
}

.table-striped {
  font-size: 11px;
  font-weight: 500;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.table-striped tr:nth-child(even) {
  background-color: #f3f4f6 !important;
}

.table-striped tr:nth-child(odd) {
  background-color: #fff !important;
}

.css-19kzrtu {
  padding: 0px !important;
}

.css-1auycx3-MuiAutocomplete-root
  .MuiOutlinedInput-root.MuiInputBase-sizeSmall {
  padding: 4px !important;
}

.user-contact {
  padding: 5px 0 5px;
  /* border-bottom: 1px solid #a4b0bc; */
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.user-contact-2 {
  flex: 1;
  padding-left: 16px;
  padding-top: 16px;
}

.user-contact-2 h3 {
  font-size: 0.85rem;
  font-weight: 400;
  color: #088dce;
  line-height: 1.2rem;
  text-transform: uppercase;
  margin-bottom: 16px;
}
.user-contact-3 {
  width: 100%;
}
.user-contact-3 h3 {
  font-size: 0.85rem;
  font-weight: 400;
  color: #088dce;
  line-height: 1.2rem;
  text-transform: uppercase;
  margin-bottom: 16px;
}
.user_profile_detail {
  display: block;
  font-size: 0.85rem;
  font-weight: 400;
  color: #344457;
  line-height: 1.8rem;
}

.contact_p {
  font-size: 0.85rem;
  font-weight: 400;
  color: #a4b0bc;
  margin-bottom: 8px;
}
.contact_span {
  font-weight: 600;
  color: #344457;
  font-size: 0.85rem;
}

.shared i {
  transform: rotate(180deg);
}

.shared,
.acting {
  color: #088dce;
  cursor: pointer;
}

.sharing {
  color: #28a745;
  cursor: pointer;
}

.shared:hover,
.acting:hover {
  color: #7ea8d4;
}

.sharing:hover {
  color: #72c786;
}

.shared:active,
.acting:active {
  color: #b3fac4;
}

.sharing:active {
  color: #cce5ff;
}

.small input {
  padding: 6.5px 14px !important;
}

.notify--info {
  display: -webkit-flex;
  display: flex;
  position: relative;
  width: 100%;
  border-radius: 8px;
  border: 1px solid transparent;
  margin-bottom: 24px;
  box-sizing: border-box;
}

.notify--info::before {
  content: "";
  position: absolute;
  top: -1px;
  left: -1px;
  border: solid 1px;
  width: 4px;
  height: 100%;
  border-radius: 8px 0 0 8px;

  background: rgb(7, 142, 206);
  border-color: rgb(7, 142, 206);
}
.notify__icon-holder {
  padding: 16px 0 0 16px;
}

.notify__content-wrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-flex: 1;
  flex: 1;
  padding: 16px 0 0 8px;
  -webkit-flex-flow: row wrap;
  flex-flow: row wrap;
}
.notify__content {
  font-family: DineroSans, sans-serif;
  color: #1c252c;
  font-weight: normal;
  -webkit-flex: 1;
  flex: 1;
  margin: 0 24px 16px 0;
  text-align: left;
  overflow-wrap: break-word;
  word-wrap: break-word;
}
.text--link {
  text-decoration: none;
  color: rgb(7, 142, 206);
  font-weight: bold;
  cursor: pointer;
}

.border-gray-300 {
  border-color: #e4e6ef !important;
}
.min-w-80px {
  min-width: 100px !important;
}
.rounded {
  border-radius: 0.85rem !important;
}

.text-gray-700 {
  color: #596394 !important;
}

.text-gray-400 {
  color: #078ece !important;
}

strong.employee-name {
  display: inline-block;
}

strong.employee-name:first-letter {
  text-transform: uppercase;
}

.border-dashed {
  border-style: solid !important;
  border-color: #078ece;
}

.notify--info,
.notify--error {
  display: -webkit-flex;
  display: flex;
  position: relative;
  width: 100%;
  border-radius: 8px;
  border: 1px solid transparent;
  margin-bottom: 20px;
  box-sizing: border-box;
}

.notify--info::before {
  content: "";
  position: absolute;
  top: -1px;
  left: -1px;
  border: solid 1px;
  width: 4px;
  height: 100%;
  border-radius: 8px 0 0 8px;

  background: rgb(7, 142, 206);
  border-color: rgb(7, 142, 206);
}
.notify--error::before {
  content: "";
  position: absolute;
  top: -1px;
  left: -1px;
  border: solid 1px;
  width: 4px;
  height: 100%;
  border-radius: 8px 0 0 8px;

  background: #dc3545 !important;
  border-color: #dc3545 !important;
}
.notify__icon-holder {
  padding: 16px 0 0 16px;
}

.notify__content-wrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-flex: 1;
  flex: 1;
  padding: 16px 0 0 8px;
  -webkit-flex-flow: row wrap;
  flex-flow: row wrap;
}
.notify__content {
  font-family: DineroSans, sans-serif;
  color: #1c252c;
  font-weight: normal;
  -webkit-flex: 1;
  flex: 1;
  margin: 0 24px 16px 0;
  text-align: left;
  overflow-wrap: break-word;
  word-wrap: break-word;
}
.text--link {
  text-decoration: none;
  color: rgb(7, 142, 206);
  font-weight: bold;
  cursor: pointer;
}

.answer__opt {
  padding: 6px;
  width: 100%;
  height: 85%;
  position: relative;
  display: flex;
  justify-content: flex-start;
  border: 1px solid #2a779045;
  border-radius: 5px;
  margin-right: 8px;
}

.answer__opt h4 {
  background-color: #088dce;
  color: #fff;
  display: block;
  height: 100%;
  width: 100%;
  text-align: center;
  line-height: 27px;
  font-size: 1rem;
  font-weight: 400;
  border-radius: 3px;
}

.answers__options {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 15px;
}

.answers__options .answer__desc {
  width: 100%;
  height: 40px;
}

.answers__options .answer__option {
  padding: 6px 0 6px 7px;
  width: 80px;
  height: 40px;
  position: relative;
  display: flex;
  justify-content: flex-start;
  border: 1px solid #2a779045;
  border-radius: 5px;
  margin-right: 8px;
}

.answers__options .answer__option h4 {
  background-color: #088dce;
  color: #fff;
  display: block;
  width: 50px;
  text-align: center;
  line-height: 27px;
  font-size: 1rem;
  font-weight: 400;
  border-radius: 3px;
  height: 100%;
}

.answers__options .answer__option .check-box {
  width: 100%;
  height: 100%;
  padding: 2px;
  border: none;
  background-color: transparent;
  position: relative;
}

.answers__options .answer__option .check-box input[type="checkbox"] {
  box-sizing: border-box;
  opacity: 0 !important;
  padding: 0;
  width: 100% !important;
  height: 100% !important;
  cursor: pointer;
  z-index: 999 !important;
}

.answers__options .answer__option .check-box .checked {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  pointer-events: none;
  position: absolute;
  top: 0;
}

.answers__options
  .answer__option
  .check-box
  .inputchekerbx:checked
  + .checked
  span {
  background: #088dce;
  border-color: #088dce;
  -webkit-animation: 0.4s wave;
  animation: 0.4s wave;
}

.answers__options .answer__option .check-box .checked span {
  position: relative;
  display: block;
  width: 24px;
  height: 24px;
  border-radius: 3px;
  transform: scale(1);
  border: 1px solid #a6d3df;
  transition: all 0.2s ease;
}

.answers__options
  .answer__option
  .check-box
  .inputchekerbx:checked
  + .checked
  span:before {
  transform: scale(3);
  opacity: 0;
  transition: all 0.6s ease;
}

.answers__options .answer__option .check-box .checked span:before {
  content: "";
  width: 100%;
  height: 100%;
  background: #088dce;
  display: block;
  transform: scale(0);
  opacity: 1;
  border-radius: 50%;
}
svg {
  overflow: hidden;
  vertical-align: middle;
}

.small-chip {
  height: 18px !important;
  font-size: 12px !important;
  background-color: #eee !important;
}

.small-chip .material-icons {
  font-size: 18px !important;
}

.table-sm td,
.table-sm th {
  padding-top: 1px;
  padding-bottom: 1px;
}

.table-hover tr:hover td {
  background-color: #f2f4f6b0;
}

.print {
  border: 1px solid #c8d7f5;
}

.print:hover {
  background-color: #f1f1f1 !important;
}

table caption {
  caption-side: top !important;
  text-align: center;
}

/* .answers__options .answer__option .check-box  .inputchekerbx:checked + .checked span i {
  stroke-dashoffset: 0;
}
.answers__options .answer__option .check-box  .checked span i {
  position: absolute;
  top: 0;
  left: 0;
  fill: none;
  color: #fff;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-dasharray: 21px;
  stroke-dashoffset: 21px;
  transition: all .3s ease;
  transition-delay: .1s;
  transform: translate3d(0,0,0);
  margin-top:2px;
  margin-left:2px
} */

.bg-danger {
  background-color: darkred !important;
  color: #fff;
}

.spinner {
  -webkit-animation: spin 1s linear infinite; /* Safari */
  animation: spin 1s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.appeal-hovered {
  border: 1px solid rgb(240, 242, 245);
  border-radius: 1px;
  /* box-shadow: inset 0px 0px 0px 1px transparent; */
}
.appeal-hovered:hover {
  background: rgba(0, 99, 207, 0.08) !important;
  border: 2px solid #376fc8;
  border-radius: 4px;
  box-shadow: inset 0px 0px 0px 1px transparent;
  cursor: pointer;
}
.align-baseline {
  vertical-align: baseline !important;
}
.align-top {
  vertical-align: top !important;
}
.align-middle {
  vertical-align: middle !important;
}
.align-bottom {
  vertical-align: bottom !important;
}
.align-text-bottom {
  vertical-align: text-bottom !important;
}
.align-text-top {
  vertical-align: text-top !important;
}
.float-start {
  float: left !important;
}
.float-end {
  float: right !important;
}
.float-none {
  float: none !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.ap-badge-primary {
  background-color: #dfe7f6;
  color: #2c58a0;
}
.ap-badge-primary i {
  color: #376fc8;
}
.ap-badge-secondary {
  background-color: #ebedef;
  color: #40464f;
}
.ap-badge-secondary i {
  color: #565e6c;
}
.ap-badge-success {
  background-color: #d6f0e0;
  color: #0d6832;
}
.ap-badge-success i {
  color: #139647;
}
.ap-badge-danger {
  background-color: #f9e1e5;
  color: #af233a;
}
.ap-badge-danger i {
  color: #d62e4a;
}
.ap-badge-warning {
  background-color: #fbf0da;
  color: #73510d;
}
.ap-badge-warning i {
  color: #a17112;
}
.ap-badge-info {
  background-color: #def1f7;
  color: #1c657d;
}
.ap-badge-info i {
  color: #2686a6;
}
.ap-badge-light {
  background-color: #f5f5f5;
  color: #4f4f4f;
}
.ap-badge-light i {
  color: #8c8c8c;
}
.ap-badge-dark {
  background-color: #332e2e;
  color: #f5f5f5;
}
.ap-badge-dark i {
  color: #e8e8e8;
}
.w-25 {
  width: 25% !important;
}
.w-50 {
  width: 50% !important;
}
.w-75 {
  width: 75% !important;
}
.w-100 {
  width: 100% !important;
}
.w-auto {
  width: auto !important;
}
.mw-100 {
  max-width: 100% !important;
}
.vw-100 {
  width: 100vw !important;
}
.min-vw-100 {
  min-width: 100vw !important;
}
.h-25 {
  height: 25% !important;
}
.h-50 {
  height: 50% !important;
}
.h-75 {
  height: 75% !important;
}
.h-100 {
  height: 100% !important;
}
.h-auto {
  height: auto !important;
}
.mh-100 {
  max-height: 100% !important;
}
.vh-100 {
  height: 100vh !important;
}
.min-vh-100 {
  min-height: 100vh !important;
}
.flex-fill {
  flex: 1 1 auto !important;
}
.flex-row {
  flex-direction: row !important;
}
.flex-column {
  flex-direction: column !important;
}
.flex-row-reverse {
  flex-direction: row-reverse !important;
}
.flex-column-reverse {
  flex-direction: column-reverse !important;
}
.flex-grow-0 {
  flex-grow: 0 !important;
}
.flex-grow-1 {
  flex-grow: 1 !important;
}
.flex-shrink-0 {
  flex-shrink: 0 !important;
}
.flex-shrink-1 {
  flex-shrink: 1 !important;
}
.flex-wrap {
  flex-wrap: wrap !important;
}
.flex-nowrap {
  flex-wrap: nowrap !important;
}
.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}
.justify-content-start {
  justify-content: flex-start !important;
}
.justify-content-end {
  justify-content: flex-end !important;
}
.justify-content-center {
  justify-content: center !important;
}
.justify-content-between {
  justify-content: space-between !important;
}
.justify-content-around {
  justify-content: space-around !important;
}
.justify-content-evenly {
  justify-content: space-evenly !important;
}
.align-items-start {
  align-items: flex-start !important;
}
.align-items-end {
  align-items: flex-end !important;
}
.align-items-center {
  align-items: center !important;
}
.align-items-baseline {
  align-items: baseline !important;
}
.align-items-stretch {
  align-items: stretch !important;
}
.align-content-start {
  align-content: flex-start !important;
}
.align-content-end {
  align-content: flex-end !important;
}
.align-content-center {
  align-content: center !important;
}
.align-content-between {
  align-content: space-between !important;
}
.align-content-around {
  align-content: space-around !important;
}
.align-content-stretch {
  align-content: stretch !important;
}
.align-self-auto {
  align-self: auto !important;
}
.align-self-start {
  align-self: flex-start !important;
}
.align-self-end {
  align-self: flex-end !important;
}
.align-self-center {
  align-self: center !important;
}
.align-self-baseline {
  align-self: baseline !important;
}
.align-self-stretch {
  align-self: stretch !important;
}

.rotated {
  transform: rotate(90deg);
  font-weight: 100 !important;
  color: #e8bc8c61;
}

.ql-editor {
  height: 200px;
  max-height: 200px;
  overflow: auto;
}

.dashboard-card {
  transition: all 0.2s linear;
}

.dashboard-card:hover {
  transform: scale(1.02);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  cursor: default;
  background-color: #f9f9f9;
}

.w-content {
  max-width: max-content;
}

.cursor-pointer {
  cursor: pointer;
}
.exam-question-canceled {
  background-color: rgb(245, 144, 144) !important;
}

.td-clickable {
  /* cursor: pointer; */
  font-size: 14px;
}
.td-clickable:hover {
  background: #078fce24;
  font-weight: 700;
  /* text-decoration: underline; */
  font-size: 15px;
}
.td-clickable-icon {
  display: none;
}
.td-clickable:hover .td-clickable-icon {
  display: block;
  margin-left: -30px;
}

.rssb-btn {
  background-color: #fff !important;
}

.rssb-btn:hover {
  background-color: #b7e5df !important;
}

.tool-bar {
  min-height: 60px !important;
}

.service-card {
  background-color: #fff !important;
  border-radius: 5px;
  border: 2px solid rgb(7, 142, 206) !important;
}

.service-card > .card-header {
  background-color: #e0edf9;
  background-color: #e0edf9;
  color: rgb(7, 142, 206);
  color: rgb(7, 142, 206);
  border-bottom: 2px solid rgb(7, 142, 206);
}
.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}
.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.text-primary {
  color: #015198 !important;
}

.support-staff:hover .ico {
  background-color: #015198 !important;
  color: #fff !important;
}

.support-staff .bg {
  color: #bebaba;
}

.support-staff:hover .bg {
  background-color: #eaf5ff !important;
  color: #015198 !important;
  font-weight: 700;
}

.support-staff:hover .user {
  color: #015198 !important;
}

.support-staff:hover .contact {
  color: #059669 !important;
}

.Mui-selected,
.nav-item.active .MuiTreeItem-content {
  background-color: #fde9d3 !important;
  color: #015198 !important;
}

.MuiTreeItem-content.Mui-focused {
  background-color: #fde9d3 !important;
}

/* .MuiTreeItem-content a {
  color: #000;
} */

.Mui-selected a {
  font-weight: bold;
  color: #b76103;
}

.MuiTreeItem-content:hover {
  background-color: #f1f1f1;
}

.Mui-selected a .material-icons {
  font-weight: bold;
  color: #b76103;
}

/* .Mui-selected .menu-header-icon {
  font-weight: bold;
  color: #015198;
} */

.MuiTreeItem-content {
  padding-top: 2px;
  padding-bottom: 2px;
  border-top-right-radius: 9999px;
  border-bottom-right-radius: 9999px;
}
/* 
.badge-primary {
  background-color: #015198 !important;
} */

/* .badge-secondary {
  background-color: #ed6c02 !important;
} */

.tr-danger td,
.tr-danger th,
.tr-danger td:hover {
  background-color: #f1c3cb76 !important;
  color: red;
}

.tmis-shadow {
  box-shadow: 0 4px 8px 0 rgb(17 22 26 / 16%), 0 4px 8px 0 rgb(17 22 26 / 8%),
    0 8px 16px 0 rgb(17 22 26 / 4%);

  border: 1px solid rgb(17 22 26 / 16%);
}

.bg-info {
  background-color: #015198 !important;
}

.bg-primary {
  background-color: #036cdc !important;
}

tr.bolder td {
  font-weight: bolder;
  color: rgb(4, 4, 224);
}

tr.bolder th {
  font-weight: bolder;
  color: rgb(4, 4, 224);
}

tr.red-bolder td {
  color: red;
  font-weight: bolder;
}

tr.red-bolder th {
  color: red;
  font-weight: bolder;
}

tr.red td {
  color: red;
  font-weight: lighter;
}

tr.red th {
  color: red;
  font-weight: lighter;
}

.text-underline {
  text-decoration: underline;
}
